@import '../../../../scss/variables.scss';

.asset-details-crown-icon {
  & button i {
    color: #e1a409;
  }

  &.clickable {
    cursor: pointer;
    pointer-events: auto;
  }

  &.no-pointer {
    cursor: default;
    pointer-events: none;
  }
}

.asset-details-crown-icon-grey {
  & button i {
    color: var(--secondary) !important;

    &:hover {
      color: #e1a409 !important;
    }
    &:active {
      color: #e1a409 !important;
    }
    &:target {
      color: #e1a409 !important;
    }
    &:active {
      color: #e1a409 !important;
    }
  }

  display: none;

  &:hover {
    display: inline;
  }

  &.clickable {
    cursor: pointer;
    pointer-events: auto;
  }

  &.read-only {
    display: none;
    cursor: default;
    pointer-events: none;
  }
}

.ag-row-hover .asset-details-crown-icon-grey {
  display: inline;
  position: absolute;
  top: 25px;
  right: 75px;

  &.no-pointer {
    display: none;
  }
}
