@import '../../scss/animations/animations.scss';
@import '../../scss/variables.scss';

.login-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  animation: fade-in 1.5s linear;

  & .alert {
    padding-right: 1.65rem;
  }

  & .close {
    padding: 0.35rem 0.45rem;
  }

  & .mb-4 {
    margin-bottom: (2rem + 1rem) !important;
  }
}

.sign-in-header,
.forgot-pw-header,
.forgot-reset-header,
.confirm-pw-header,
.failed-reg-header,
.confirm-reg-header,
.confirm-success-header,
.success-pw-header {
  text-align: center;
  font-weight: bold;
  color: var(--c-primary);
  margin-bottom: 60px;
}

.sign-in-form,
.forgot-pw-form,
.sign-in-back-container,
.sso-container,
.confirm-pw-form,
.reset-pw-btn-container,
.try-again-container {
  margin: auto;
  width: 70%;

  & .sign-in-input,
  .forgot-pw-input,
  .prepend-icon {
    height: 40px;
  }

  & .forgot-pw {
    float: right;
    margin-bottom: 20px;
  }

  & .input-group-text {
    background-color: white;
    padding-top: 14px;
  }

  & .sign-in-btn,
  .reset-pw-btn,
  .sign-in-back-btn,
  .sso-btn,
  .try-again-btn {
    width: 100%;
  }

  & .sso-btn {
    background-color: white !important;
    color: var(--c-primary) !important;
    border: solid 1px var(--c-primary) !important;
  }
}

.forgot-pw-btn {
  color: var(--c-primary) !important;
}

.sign-in-error-container {
  text-align: center;
  margin: auto;
  margin-bottom: 10px;
  padding: 7px;
  color: var(--danger);
  font-weight: 400;

  & p {
    margin: 0;
  }
}

.sign-in-form {
  .sonarIcon-mail,
  .sonarIcon-password {
    color: var(--c-primary);
  }
}

.forgot-pw-form {
  display: grid;
  gap: 40px;

  & .mail-icon {
    color: var(--primary);
  }
}

.faded-out-text {
  width: 70%;
  text-align: center;
  border-bottom: 1px solid #dedddd;
  color: #c2c2c2;
  line-height: 0.1em;
  margin: 60px auto;

  & span {
    background: white;
    padding: 0 10px;
  }
}

.pw-rules-container {
  width: 70%;
  margin: auto;
}

@media (max-width: 992px) {
  .login-wrapper .right-login {
    padding-bottom: 6rem !important;
  }

  .left-login {
    display: none;
  }
}

@media (max-width: 500px) {
  .sign-in-form,
  .forgot-pw-form,
  .sign-in-back-container,
  .sso-container,
  .confirm-pw-form,
  .reset-pw-btn-container,
  .try-again-container {
    width: 90%;
  }
}

.btn-secondary-sso {
  background-color: var(--c-white) !important;
  color: var(--c-primary) !important;
  border: 1px solid var(--c-primary) !important;
  padding: 6px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary-sso:hover {
  color: var(--c-white) !important;
  background-color: var(--c-primary) !important;
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary-sso:focus {
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary-sso:active {
  border: 1px solid var(--c-primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary-sso:disabled {
  background-color: var(--c-white) !important;
  color: var(--nav-text) !important;
  border: 1px solid var(--nav-text) !important;
  box-shadow: none !important;
  outline: none !important;
}
