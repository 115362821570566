@import '../../../scss/variables.scss';

.override-modal {
  & .cancel,
  .yes {
    margin: 0.5rem;
  }
  & .download-csv {
    color: var(--primary);
  }
}
