.fs-xs {
  font-size: 0.75em;
}

.fs-sm {
  font-size: 0.875em;
}

.fs-md {
  font-size: 1.25em;
}

.fs-lg {
  font-size: 1.5em;
}

.fs-1x {
  font-size: 1.75em;
}

.fs-2x {
  font-size: 2em;
}

.fs-3x {
  font-size: 3em;
}

.fs-4x {
  font-size: 4em;
}

.fs-5x {
  font-size: 5em;
}

.fs-6x {
  font-size: 6em;
}

.fs-7x {
  font-size: 7em;
}

.fs-8x {
  font-size: 8em;
}

.fs-9x {
  font-size: 9em;
}

.fs-10x {
  font-size: 10em;
}
