@import '../../../scss/variables.scss';

.center-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 45px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  z-index: 999;
}

.map-controls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.layers-button {
  padding: 8px;
  border: none !important;
  background-color: var(--body) !important;
}

.opacity-slider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  margin-right: 10px;
}

.center-button {
  padding: 8px;
  border: none !important;
  background-color: var(--body) !important;
}

.leaflet-container {
  background-color: white !important;
}

.leaflet-control-button {
  cursor: pointer;
}

.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair !important;
}

.measure-label {
  font-weight: bold;
  border: none !important;
  box-shadow: none !important;
}

input[type='range']::-webkit-slider-thumb {
  background: var(--primary);
}

input[type='range']::-moz-range-thumb {
  background: var(--primary);
}

input[type='range']::-ms-thumb {
  background: var(--primary);
}

input[type='range']::-webkit-slider-thumb:active {
  background: var(--primary);
}

input[type='range']::-moz-range-thumb:active {
  background: var(--primary);
}

input[type='range']::-ms-thumb:active {
  background: var(--primary);
}

input[type='range']::-webkit-slider-runnable-track {
  background-color: var(--white) !important;
  border: 1px solid var(--primary);
}

input[type='range']::-moz-slider-runnable-track {
  background-color: var(--white);
  border: 1px solid var(--primary);
}

input[type='range']::-ms-tslider-runnable-track {
  background-color: var(--white);
  border: 1px solid var(--primary);
}

.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.marker-pin::after {
  content: '';
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.custom-div-icon i {
  position: absolute;
  width: 16px;
  font-size: 16px;
  left: 0;
  right: 0;
  margin: 12px auto;
  text-align: center;
}

.custom-div-icon b {
  position: absolute;
  width: 26px;
  font-size: 11px;
  top: 3px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

.custom-div-icon i.awesome {
  margin: 12px auto;
  font-size: 17px;
}

path.leaflet-interactive:focus {
  outline: none;
}
