@import '../../../../scss/variables.scss';

.edit {
  display: inline;
  visibility: hidden;
}

.show.edit {
  display: inline;
  visibility: visible;
}

.ag-row-hover .edit {
  display: inline;
  visibility: visible;
}

.empty {
  display: inline;
  visibility: hidden;
}

.show.empty {
  display: inline;
  visibility: visible;
}

.ag-row-hover .empty {
  display: inline;
  visibility: visible;
}

.favorite-edit {
  & .sonarIcon-edit,
  .sonarIcon-star-fill,
  .sonarIcon-star-empty {
    color: var(--primary);
  }
}

.danger-icon {
  color: var(--danger);
}

.primary-icon {
  color: var(--primary);
}

.nav-text-icon {
  color: var(--nav-text) !important;
}
