@import '../../../../scss/variables.scss';

.user-text-btn {
  background-color: var(--white);
  border: none;
  text-align: left;
}

.acc-mgm-user-profile-container {
  & .user-profile-pic {
    background-color: var(--nav-text);
    border-radius: 50%;
    padding: 3em;
    margin: 2em auto;
    position: relative;
    width: 150px;
    height: 150px;
    text-align: center;
  }

  & .user-initials {
    margin-top: 30px;
  }

  & .user-profile-info {
    line-height: 25px;

    & .user-role-text {
      font-size: 1.3em;
      font-weight: 400;
      padding-bottom: 10px;
    }
  }

  & button > i.fa-times {
    font-size: 24px;
    font-weight: 300;
  }
}
