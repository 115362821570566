.toast-failure {
  border-radius: 10px;
  height: 100%;
  font-size: 1.1em;
  background-color: var(--danger);
}

.toast-success {
  border-radius: 10px;
  height: 100%;
  font-size: 1.1em;
  background-color: var(--c-primary);
}

.toast-info {
  border-radius: 10px;
  height: 100%;
  font-size: 1.1em;
  background-color: var(--nav-text);
}
