@import '../variables.scss';

.swal2-content,
.swal2-modal,
.modal-content {
  background-color: var(--white);
  color: var(--dark);

  .text-dark {
    color: var(--nav-text) !important;
  }

  .list-group-item {
    background-color: var(--white) !important;
  }
}

.swal2-modal {
  box-shadow: 0 0 28px -5px rgba(3, 3, 3, 0.43);
  -webkit-box-shadow: 0 0 28px -5px rgba(3, 3, 3, 0.43);
  -moz-box-shadow: 0 0 28px -5px rgba(3, 3, 3, 0.43);
}

.swal-delete-user {
  & .swal-title:not(:last-child) {
    margin-bottom: 0;
  }

  & .swal-content {
    margin-top: 0;
  }

  & .user-name {
    font-weight: bold;
    margin-bottom: 13px;
    word-wrap: break-word;
  }
}

.swal2-footer {
  text-align: center;
}

.swal2-confirm {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.swal2-confirm:not([disabled]):hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  box-shadow: none !important;
  outline: none !important;
  text-decoration: underline;
}

.swal2-cancel {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.swal2-cancel:not([disabled]):hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  box-shadow: none !important;
  outline: none !important;
  text-decoration: underline;
}

.swal2-button-left {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.swal2-button-left:not([disabled]):hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  box-shadow: none !important;
  outline: none !important;
}

.swal2-button-right {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.swal2-button-right:not([disabled]):hover {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  box-shadow: none !important;
  outline: none !important;
}

.swal2-modal .swal2-text {
  text-align: center;
  color: var(--dark);
}

.swal2-modal .swal2-title {
  text-align: center;
  color: var(--dark);
}

.loading {
  background-color: transparent;
  width: auto;
  height: auto;
}

.uploadItems .swal-content {
  max-height: 50vh;
  overflow-y: scroll;
}

.list-group-item.default-asset-filter {
  background-color: var(--white) !important;
}

.user-info-labels {
  color: var(--dark);
}
