@import '../../scss/variables.scss';

.beacon-ability-content-wrap {
  border: solid 1px var(--back-highlight);
  border-radius: 8px;
  background-color: var(--back-highlight);
  display: flex;
  justify-content: space-between;
  width: 60%;

  & button {
    padding: 8px 6px 5px 8px;
  }

  & i {
    color: var(--primary);
  }
}

.asset-list-beacon-readings-wrapper {
  height: auto;
  max-height: calc(100vh - 300px);
  overflow-x: auto;

  & .asset-details {
    & button {
      padding-top: 0 !important;
    }

    & i {
      color: var(--primary);
    }

    & p {
      padding-top: 3px;
    }

    font-size: 16px;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    filter: var(--invert);
  }

  & .carousel-indicators button {
    display: none;
  }
}
