@import '../../scss//variables.scss';

// AdvancedAsset
.loading-wrap-advanced-asset {
  padding-top: 250px;
}

.advanced-asset-row {
  min-height: 500px;
}

.card-last-reading {
  min-height: 100%;
}

.advanced-asset-main-card {
  width: 85%;
}

.asset-toggle-switch {
  & input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

#normalize-checkbox:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

// Beacons Table
.adv-asset-beacons-table {
  color: var(--dark);
}

// Summary Card
@media (max-width: 992px) {
  #card-summary-container {
    padding-bottom: 3rem;
    flex-direction: row !important;
    justify-content: space-between;

    & .card {
      margin-bottom: 0 !important;
      width: 30%;
    }
  }
}

.card-subtitle a {
  color: var(--primary) !important;
}

// Graph Container
@media (max-width: 1575px) {
  .advanced-graph-container {
    flex-flow: wrap;
    margin-top: 20px !important;
  }
}

@media (max-width: 1000px) {
  .advanced-asset-asset-header {
    flex-flow: wrap;
  }
}

// CheckboxDropdown

.dropdown-menu.CheckboxMenu {
  position: absolute;
  inset: 0 auto auto 0;
  transform: translate(0, 40px);

  & .drop-down-item-inner {
    max-height: calc(100vh);
    overflow: none;

    & ul {
      overflow: auto;
    }
  }
}

.dropdown-item {
  padding: 0.25rem 0.75rem !important;
}

.beacon-list-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .sonarIcon-beacon {
    color: var(--primary);
  }

  & .back-btn {
    width: 210px;
  }
}

.advanced-asset-right-button-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-top: 10%;
  padding-left: 6%;

  & button.secondary-background {
    color: var(--white);
    cursor: auto;
    background-color: var(--secondary);
    padding: 10px 20px;

    &:hover {
      color: var(--white);
      background-color: var(--secondary);
    }

    &:active {
      color: var(--white) !important;
      background-color: var(--secondary) !important;
    }
  }

  & button.primary-background {
    color: var(--white);
    cursor: pointer;
    background-color: var(--primary);
    padding: 10px 20px;

    &:hover {
      color: var(--white);
      background-color: var(--primary-gray);
    }

    &:active {
      color: var(--white) !important;
      background-color: var(--primary-gray) !important;
    }
  }
}

// Advanced Charting - Descriptive Statistics Cards

.statistics-card-wrapper {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: rgb(253, 253, 253);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 6px;
  }

  & .card {
    width: max-content;
    margin-left: 10px;
    margin-right: 10px;

    & .color-0048ff {
      color: #0048ff;
    }

    & .color-f178b6 {
      color: #f178b6;
    }

    & .color-e7bd54 {
      color: #e7bd54;
    }

    & .color-008392 {
      color: #008392;
    }

    & .color-002ecb {
      color: #002ecb;
    }

    & .color-ff6f00 {
      color: #ff6f00;
    }

    & .color-b7f178 {
      color: #b7f178;
    }

    & .color-9454e7 {
      color: #9454e7;
    }

    & .color-922900 {
      color: #922900;
    }

    & .color-cb5b00 {
      color: #cb5b00;
    }

    & .color-ffee00 {
      color: #fe0;
    }

    & .color-78f1ef {
      color: #78f1ef;
    }

    & .color-e754a7 {
      color: #e754a7;
    }

    & .color-927200 {
      color: #927200;
    }

    & .color-cbc100 {
      color: #cbc100;
    }

    & .color-dd00ff {
      color: #d0f;
    }

    & .color-f19678 {
      color: #f19678;
    }

    & .color-a7e754 {
      color: #a7e754;
    }

    & .color-500092 {
      color: #500092;
    }

    & .color-b300cb {
      color: #b300cb;
    }

    & .no-border-right {
      border-right: none;
    }

    & .solid-border-right {
      border-right: 2px solid #c3d1d9;
    }

    & .add-padd {
      padding-top: 10px;
    }

    & .no-padd {
      padding-top: 0;
    }
  }

  & thead {
    border-bottom: 2px solid #c3d1d9;
  }

  & table {
    width: -webkit-fill-available;
  }

  & .ability-label {
    padding-left: 20px;
    text-wrap: nowrap;
  }

  & .ability-stats {
    padding-left: 15px;
    padding-right: 30px;
  }
}

.advanced-charting.row > * {
  max-width: none !important;
}

.advanced-asset-all-cards-wrapper {
  & .date-headers {
    background-color: var(--white);
    width: 85%;
    height: 50px;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & h6 {
      color: var(--dark);
    }
  }

  & .statistics-card-wrapper {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-left: 0;
  }

  & .statistic-card {
    max-width: fit-content;
  }
}

.all-run-checkbox {
  background-color: #f4f7f9;
  margin-bottom: 0 !important;
  padding-left: 2.2em !important;
  padding-top: 0.5em;

  & .form-check-label {
    font-weight: 500;
  }
}
