#custom-switch:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.dark-icon {
  color: var(--dark);
}

.icon-wrap {
  pointer-events: none;
  background-color: transparent;
}
