@import './../../../scss/variables.scss';

.MuiOutlinedInput-input {
  padding: 7px 6px !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  z-index: inherit;
}

.MuiTab-wrapper {
  & .MuiSvgIcon-root {
    fill: white !important;
  }
}

.Mui-disabled {
  & .MuiSvgIcon-root {
    fill: #0048ff5e !important;
  }
}
