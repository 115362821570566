@import '../../../../scss/variables.scss';

.measurement-row {
  & .sonarIcon-info {
    color: var(--dark);
  }
  & .edit-static-m-btn {
    margin: 10px;
  }
}
