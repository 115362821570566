@import '../../scss/variables.scss';

.places-detail-header {
  overflow: hidden;

  & .place-detail-favorite-icon {
    color: var(--primary);
  }
}

#placeScreenGroupedGrid > div.table-responsive {
  min-height: 600px;
  height: 65vh;
}

.place-detail-stationary-beacon-map-wrap {
  & i {
    color: var(--primary);
  }
}

.place-map > div {
  height: 90% !important;
}

@media (max-width: 1750px) {
  .place-grid {
    margin-bottom: 25px;
  }
}

@media (min-width: 1750px) {
  .place-grid,
  .place-map {
    width: 50% !important;
  }
}

.place-name-primary {
  color: var(--primary);
}
