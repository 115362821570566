@import '../../../../scss/variables.scss';

.modal-body.beacon-abilities-modal-body {
  width: 100%;
  height: 330px;

  & .close-wrapper {
    top: 10px;
    right: 20px;

    & button > i.fa-times {
      font-size: 24px;
      font-weight: 300;
    }
  }

  & .ag-theme-material {
    height: 250px;
    width: 96%;
    position: absolute;
  }
}

.sonarIcon-edit {
  color: var(--primary);
}
