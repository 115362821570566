@import '../../scss/variables.scss';

.remove-favorite {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.favorite-tab:hover > .remove-favorite {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

//Favorite Accordion Tabs

.favorite-accordion {
  .primary-color {
    background-color: var(--c-primary);
  }

  .alt-color {
    background-color: var(--alt1);
  }

  .tertiary-color {
    background-color: var(--tertiary);
  }
}

.favorite-accordion-header {
  color: var(--dark);
  user-select: none;

  & .icon-wrap {
    border-radius: 60px;

    & i {
      color: var(--white);
      font-weight: normal;
    }
  }
}

.favorite-accordion-body {
  & p {
    color: var(--dark);
  }

  & i {
    color: var(--nav-text);
  }

  & span {
    color: var(--dark) !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Recently Viewed Accordion Tab
.recently-viewed-accordion {
  .primary-color {
    color: var(--c-primary);
  }

  .alt-color {
    color: var(--alt1);
  }

  .tertiary-color {
    color: var(--tertiary);
  }
}

.recently-viewed-accordion-header {
  color: var(--dark);

  & .icon-wrap {
    border-radius: 60px;
    background-color: var(--nav-text) !important;

    & i {
      color: var(--white);
      font-weight: normal;
    }
  }
}

.recently-viewed-accordion-body {
  & button {
    overflow: hidden;
    text-overflow: ellipsis;

    & i {
      padding: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & span {
      color: var(--dark) !important;
    }
  }
}
