@import '../../scss/variables.scss';

.delete-modal {
  width: 50vw !important;
}

.back-btn {
  color: var(--primary);
  border-color: var(--primary);
}

.back-btn:hover {
  color: var(--white);
  background-color: var(--primary);
}

.next-btn {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.next-btn:hover {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
