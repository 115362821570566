@import '../../scss/variables.scss';

.invite-btn,
.upload-btn {
  grid-gap: 15px;
}

.role-select,
.remove-user {
  height: fit-content;
}

.user-mgmt-add-email-btn,
.user-mgmt-remove-email-btn {
  width: 15%;
}

.user-mgmt-form-input {
  width: 26%;
}
