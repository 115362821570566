@use 'sass:math';

// Global Animations

// animation-fill-mode: forwards; Maintain final state of animation example(fade out to stay hidden).

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade-out {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fade-and-slide {
  0% {
    opacity: 0;
    transform-origin: center;
    transform: scale(1.2);
  }

  10% {
    opacity: 0;
    transform-origin: center;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform-origin: center;
    transform: scale(1) translate(0, 0);
  }
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

//   Circle Check Animation
$anim-size: 1.5em;
$check-height: math.div($anim-size, 2);
$check-width: 0.45em;
$check-left: 0.245em;
$check-thickness: 3px;

.circle-anim {
  background-color: #0147fb;
  border-radius: 50%;
  border: 2px solid #0147fb;
  width: $anim-size;
  height: $anim-size;
  margin-right: 1em;
  position: relative;
}

.checkmark {
  &.draw::before {
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &::before {
    border-radius: 1px;
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid white;
    border-top: $check-thickness solid white;
    content: '';
    left: $check-left;
    top: 0.65em;
    position: absolute;
  }
}

.primary-checkmark {
  &.draw::before {
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &::before {
    border-radius: 1px;
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid #0048ff;
    border-top: $check-thickness solid #0048ff;
    content: '';
    left: $check-left;
    top: 0.65em;
    position: absolute;
    margin-top: 2px;
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }

  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }

  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }

  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
