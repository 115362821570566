@import '../../../scss/variables.scss';

.filter-sm-name {
  color: var(--dark);
}

#identifier,
#device-id,
#keyword,
#single-asset {
  background: var(--white);
}
