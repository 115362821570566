@each $color, $value in $theme-colors {
  .sonarIcon-#{$color} {
    color: #{$value};
  }
}

.sonarIcon-circle {
  border: 1px solid $gray-500;
  border-radius: 50%;
  padding: 4px;
}

.sonarIcon-circle-invisible {
  border: 1px solid transparent;
  border-radius: 50%;
  padding: 4px;
}

.sonarIcon-rotate-45 {
  // filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
