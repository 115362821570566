@import '../../../../scss/variables.scss';

.edit-delete-cellRenderer {
  display: none;

  & .sonarIcon-check {
    color: var(--primary);
  }

  & .sonarIcon-edit {
    color: var(--primary);
  }

  & .sonarIcon-add {
    color: var(--danger);
  }
}

.show.edit-delete-cellRenderer {
  display: inline;
}

.ag-row-hover .edit-delete-cellRenderer {
  display: inline;
}
