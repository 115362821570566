@import './../../scss/variables.scss';

.badge {
  margin-right: 8px;
  margin-bottom: 8px;
}

span.badge.badge-pill {
  margin: 0.7em;
  padding: 0.9em;
}

.keyword-text {
  font-size: 1.5em;
  margin-left: 10px;
  color: var(--body) !important;
}

.keyword-text-noedit {
  font-size: 1.5em;
  color: var(--body) !important;
}

.keyword-input {
  border: none;
  border-radius: 2em;
  margin-left: 2px;
  height: 25px;
  padding-left: 10px;
}

.keyword-add {
  color: var(--body);
}

input.keyword {
  background-color: var(--primary) !important;
  border: none;
  border-radius: 2em 0.5em 0.5em 2em;
  color: var(--body) !important;
  font-size: 1.5em;
  margin-left: 2px;
  padding-left: 10px;
  outline: none;

  &::placeholder {
    color: var(--white);
  }
}

.beacon-type-keywords {
  background: var(--primary);
  margin: 8px;

  & span.text {
    font-size: 1.5em;
    padding: 10px;
  }
}

.keywords-delete-icon {
  color: var(--body);
}
