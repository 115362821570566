@import '../../scss/variables.scss';

.checkbox-wrapper-1 input {
  display: none;
}

.checkbox-wrapper-1 .cbx {
  -webkit-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
}

.checkbox-wrapper-1 .cbx span {
  display: inline-block;
  vertical-align: middle;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-1 .cbx span:first-child {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: scale(1);
  vertical-align: middle;
  border: 1px solid rgb(255 255 255 / 10%);
  transition: all 0.2s ease;
}

.checkbox-wrapper-1 .cbx span:first-child svg {
  position: absolute;
  z-index: 1;
  top: 7px;
  left: 5px;
  fill: none;
  stroke: #f9f9f9;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 16px;
  stroke-dashoffset: 16px;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  transform: translate3d(0, 0, 0);
}

.checkbox-wrapper-1 .cbx span:first-child::before {
  content: '';
  width: 100%;
  height: 100%;
  background: var(--primary);
  display: block;
  transform: scale(0);
  opacity: 1;
  border-radius: 50%;
  transition-delay: 0.2s;
}

.checkbox-wrapper-1 .cbx:hover span:first-child {
  border-color: var(--primary);
}

.checkbox-wrapper-1 .inp-cbx:checked + .cbx span:first-child {
  border-color: var(--primary);
  background: var(--primary);
  animation: check-1 0.6s ease;
}

.checkbox-wrapper-1 .inp-cbx:checked + .cbx span:first-child svg {
  stroke-dashoffset: 0;
}

.checkbox-wrapper-1 .inp-cbx:checked + .cbx span:first-child::before {
  transform: scale(2.2);
  opacity: 0;
  transition: all 0.6s ease;
}

.checkbox-wrapper-1 .inp-cbx:checked + .cbx span:last-child {
  color: rgb(255 255 255 / 10%);
  transition: all 0.3s ease;
}

.checkbox-wrapper-15 .inp-cbx:checked + .cbx span:last-child::after {
  transform: scaleX(1);
  transition: all 0.3s ease;
}

@keyframes check-1 {
  50% {
    transform: scale(1.2);
  }
}
/* Styles for Checkbox 2 */
.checkbox-wrapper-2 .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-wrapper-2 .container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
  border-radius: 50%;
  scale: 1.1;
  background-color: rgb(255 255 255 / 10%);
}

.checkbox-wrapper-2 .checkmark-2 {
  position: relative;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  transition: 0.3s;
  transform: scale(0);
  border-radius: 50%;
}

.checkbox-wrapper-2 .container input:checked ~ .checkmark-2 {
  background-color: var(--primary);
  transform: scale(1);
}

.checkbox-wrapper-2 .checkmark-2::after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-wrapper-2 .container input:checked ~ .checkmark-2::after {
  display: block;
}

.checkbox-wrapper-2 .container input:checked ~ .celebrate {
  display: block;
}

.checkbox-wrapper-2 .container .checkmark-2::after {
  left: 50%;
  top: 50%;
  width: 8px;
  height: 12px;
  border: solid #f9f9f9;
  border-width: 0 0.15em 0.15em 0;
  transform: translate(-50%, -55%) rotate(45deg);
}

.checkbox-wrapper-2 .container .celebrate {
  position: absolute;
  transform-origin: center;
  animation: kfr-celebrate 0.4s;
  animation-fill-mode: forwards;
  display: none;
  stroke: var(--primary);
}

@keyframes kfr-celebrate {
  0% {
    transform: scale(0);
  }

  50% {
    opacity: 1;
  }

  100% {
    transform: scale(1.2);
    opacity: 0;
    display: none;
  }
}
