@import '../../../scss/variables.scss';

.csv-failed-link,
.csv-success-link {
  color: var(--primary);
}

button.beacon-type {
  font-size: 0.8rem;
}

.results-modal-upload {
  & .result-success-link {
    text-decoration: none;
  }

  & span.text {
    color: var(--dark);
  }
}
