.coordinates-set {
  border-color: transparent !important;
  font-size: 14px;
  text-decoration: none !important;
  cursor: arrow !important;

  &:hover {
    text-decoration: none;
  }
}

.set-coordinates {
  border-color: transparent !important;
  font-size: 14px;
}

.btn-enabled {
  cursor: pointer;
}

.btn-disabled {
  text-decoration: none !important;
  cursor: not-allowed !important;
  opacity: 0.5;
}
