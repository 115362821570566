@import '../../../../scss/variables.scss';

.create-asset-success {
  & button.create-asset {
    width: 200px;
  }
}

.next-btn {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.next-btn:hover {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
