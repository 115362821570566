@import '../../../scss/variables.scss';

.breadcrumb-input-table-upper-wrapper {
  & .cg-text {
    color: var(--coverage-grey);
  }

  & .cg-table-highlight {
    color: var(--c-coverage-grey);
    background-color: var(--c-primary);
  }

  tr.cg-table-highlight td {
    background-color: unset; /* Unset background color for the td elements */
    color: unset;
  }

  & .cg-table-highlight-default {
    color: var((--coverage-grey));
  }

  & .cg-table-highlight-default:hover {
    color: var(--c-primary);
  }

  & .cg-table-col {
    color: var(--coverage-grey);
  }

  & .cg-table-colClicked {
    color: var(--c-primary);
  }
}

.bread-crumbs-table-container {
  & .card {
    margin-bottom: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    & .number-input-container {
      padding-top: 2px;
    }

    @media (max-width: 1100px) {
      & .date-number-input-container {
        display: contents !important;

        & .number-input-container {
          padding-top: 30px;
        }
      }
    }
  }
}

//Breadcrumbtable.js

.breadcrumb-input-table-upper-wrapper {
  & .loading-wrapper {
    height: 197px;
    & .loading-lottie {
      width: 180px !important;
      height: 180px !important;
    }
  }
  & .scroll-table {
    max-height: 20vh;
    overflow: auto;
    padding: 1rem 1rem 0.4rem 0;
    margin-top: 10px;
  }

  & .number-input-container {
    & input {
      width: 3rem;
      height: 14px;
      text-align: center;
      border-radius: 4px;
      box-sizing: content-box;
      padding: 7.5px 5px;
      border: solid 1px #dee2e6;
      margin-right: 5px;

      &:focus-visible {
        border: solid 1px var(--primary) !important;
        outline: none !important;
      }
    }
  }
}

.restart-sequence-btn {
  margin-bottom: 16px;
  & .playback-speed {
    z-index: 500 !important;
  }
}

.slider-container {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
  width: 60%;
}

.date-number-input-container {
  display: flex;
  gap: 50px;
  justify-content: space-between;
}

.wrap-date-submit-breadcrumb {
  display: flex;
  gap: 50px;
  align-items: center;
  width: 80%;
}

.wrap-pager-viewer-breadcrumb {
  width: 30%;
  justify-content: flex-end;
  gap: 20%;
}

@media (max-width: 1650px) {
  .wrap-date-submit-breadcrumb {
    width: 70%;
  }
  .date-number-input-container {
    gap: 10px;
  }
}

@media (max-width: 1505px) {
  .wrap-date-submit-breadcrumb {
    width: 60%;
  }
  .wrap-pager-viewer-breadcrumb {
    width: 40%;
  }
}

@media (max-width: 1320px) {
  .date-number-input-container {
    flex-direction: column;
    gap: 20px;
  }
  .wrap-date-submit-breadcrumb {
    width: 100%;
  }

  .wrap-pager-viewer-breadcrumb {
    width: 100%;
    align-items: flex-end;
  }
}
