@import '../../../../scss/variables.scss';

.success-create-asset {
  & p {
    font-size: 16px;
    color: var(--dark);
  }

  & a,
  b {
    color: var(--primary);
  }

  margin-bottom: 50px;
}

.lottie-container-create-asset {
  margin-top: 150px;
}

.lottie-check-create-asset {
  width: 250px;
  height: 250px;
}
