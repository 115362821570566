@use '~ag-grid-community/styles' as ag;

@include ag.grid-styles(
  (
    theme: material,
  )
);

@import './scss/variables.scss';
/* || BOOTSTRAP REGION */

@import '~bootstrap/scss/bootstrap.scss';
/* || BOOTSTRAP OVERWRITING DEFAULT VARIABLES */

$primary: var(--c-primary);
$danger: var(--danger);

$theme-colors: map-merge(
  $theme-colors,
  (
    'primary': $primary,
    'danger': $danger,
  )
);
/* || BOOTSTRAP END REGION */
/* || CUSTOM SCSS */
@import '/node_modules/react-grid-layout/css/styles.css';
@import '/node_modules/react-resizable/css/styles.css';
@import './scss/index.scss';
@import './components/index.scss';
@import './screens/index.scss';
@import './scss/comps/Navigation.scss';
@import url(//fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,300;0,500;0,700;1,300;1,400;1,700&display=swap);
/* || AG-GRID REGION */
/* || CUSTOM SERVER-SIDE-GRID /AG-GRID */

.ag-theme-material {
  .text-dark {
    color: var(--dark) !important;
    text-decoration: none;

    &:hover {
      color: var(--dark) !important;
      text-decoration: underline;
    }
  }

  .ag-overlay-wrapper {
    background-color: var(--light) !important;
  }

  .ag-root,
  .ag-header,
  .ag-cell,
  .ag-body {
    background-color: var(--light) !important;
    color: var(--dark) !important;
  }

  .ag-cell {
    display: flex;
    align-items: center;
    justify-content: left;
  }

  .ag-header-cell.ag-focus-managed:hover {
    background-color: var(--body) !important;
    color: var(--c-primary) !important;

    .ag-header-cell-text,
    .ag-sort-indicator-container {
      color: var(--c-primary) !important;
    }
  }

  .ag-header-cell-text,
  .ag-sort-indicator-container {
    color: var(--dark) !important;
  }

  .ag-header-cell-sorted-none {
    color: var(--dark);
    font-size: 1em;

    & i {
      color: var(--primary);
    }
  }

  .ag-header-cell.ag-focus-managed.ag-header-cell-sortable.ag-theme-custom-text-right {
    font-size: small !important;
    font-weight: bold !important;
    justify-content: right;

    & div {
      color: var(--dark);

      & i {
        color: var(--dark);
      }
    }

    i {
      color: var(--c-primary);
    }

    &:hover div {
      color: var(--c-primary);

      & i {
        color: var(--c-primary);
      }
    }
  }

  .ag-cell.ag-cell-not-inline-editing.ag-cell-auto-height.ag-right-aligned-cell.ag-cell-value {
    justify-content: right;
  }

  .ag-body {
    background-color: var(--white);
    color: var(--dark);
  }

  .ag-cell-focus,
  .ag-cell-no-focus {
    border: none !important;
  }

  // fixes editing cell height
  .ag-cell-inline-editing {
    height: 100%;
  }

  // hide scrollbar tracks on grid unless on hover
  ::-webkit-scrollbar-track {
    background: none;
    box-shadow: none;
  }
}
/* || AG-GRID END REGION */

.popover {
  padding: 1rem;
}

@include generateUnits('height', 'vh', 'vh');
@include generateUnits('height', 'h', '%');
@include generateUnits('width', 'vw', 'vw');
@include generateUnits('width', 'w', '%');

* {
  box-sizing: border-box;
}

.h-auto {
  height: auto;
}

.w-auto {
  width: auto;
}

html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans', 'sans-serif', serif;
  font-weight: 300;
  font-size: 14px;
  color: #000;
  background-color: var(--body);
  height: 100%;
  overflow: hidden;
}

#root {
  height: 100%;
  width: 100%;
}
/* || CUSTOM SCROLLBAR REGION */

::-webkit-scrollbar {
  width: 10px;
  border-radius: 10px;
}

// Track
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  background-color: var(--body);
}

// Handle
::-webkit-scrollbar-thumb {
  background: var(--nav-text);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--dark);
  border-radius: 10px;
}
/* || CUSTOM SCROLLBAR END REGION */

.pointer {
  cursor: pointer;
}

.circle {
  border-radius: 50%;
}

.card {
  .text-dark {
    color: var(--dark) !important;
  }

  background-color: var(--white);
  border-radius: $card-radius;
  border: none;
  box-shadow: var(--box-shadow-right);
  color: var(--dark);
}

.card-header {
  background-color: inherit;
  border-top-left-radius: $card-radius !important;
  border-top-right-radius: $card-radius !important;
  border-bottom-color: var(--cardBorderBottom);
}

.card-title {
  color: var(--dark);
}

.card-body {
  border-bottom-left-radius: $card-radius !important;
  border-bottom-right-radius: $card-radius !important;
}

.align-sub {
  vertical-align: sub;
}

.hover-light {
  &:hover {
    color: var(--light);
  }
}

.form-control {
  box-shadow: none !important;
}

.form-control:focus {
  box-shadow: none !important;
  border-color: var(--primary) !important;
}

.form-select {
  box-shadow: none !important;
}

.form-select option:hover {
  background-color: var(--primary) !important;
}

.form-select:focus {
  box-shadow: none !important;
  border-color: var(--primary) !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--c-primary);
  background-color: var(--c-primary);
  box-shadow: none;
}

.nav-tabs .nav-link.active {
  background-color: var(--white);
  color: var(--primary);
  border-bottom-color: var(--primary) !important;
}

.col-form-label {
  color: var(--dark);
}

.dropdown-menu {
  background-color: var(--dropdown);
}

.dropdown-item {
  background-color: var(--body) !important;
  color: var(--dark) !important;

  &.active {
    background-color: var(--primary) !important;
    color: var(--white) !important;
  }
}

.dropdown-item:hover {
  background-color: var(--secondary) !important;
}

a.dropdown-item:hover {
  background-color: var(--dropdown);
}

.popover,
.popover-header,
.popover-content {
  background-color: var(--dropdown);
  color: var(--dark);
}

.bs-popover-bottom .arrow::after {
  border-bottom-color: var(--dropdown) !important;
}

.bs-popover-left .arrow::after {
  border-left-color: var(--dropdown) !important;
}

.bs-popover-bottom .popover-header::before {
  border: none;
}

.alert {
  padding: 0.55rem 1.25rem;
}

.fa.fa-times {
  color: var(--dark);
}

.bg-primary {
  background-color: var(--primary) !important;
  align-items: center;
  justify-content: space-between;
}

.bg-danger {
  background-color: var(--danger) !important;
  align-items: center;
  justify-content: space-between;
}

.bg-light {
  background-color: transparent !important;
  align-items: center;
  justify-content: space-between;
}
// React-Grid-Layout
.react-grid-item.react-grid-placeholder {
  background-color: var(--nav-text);
}

.react-resizable-handle.react-resizable-handle-se {
  padding: 0 10px 10px 0;
}

.react-resizable-handle.react-resizable-handle-se::after {
  opacity: 0;
}

// Page not found

i.page-not-found {
  font-size: 2em;
}
