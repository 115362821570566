@import '../../scss/variables.scss';

.asset-dropdown {
  & .list-group-item {
    background-color: var(--dropdown);
  }

  & .edit-btn {
    color: var(--dark);
  }

  & .delete-btn {
    color: #f32013;
  }
}

.sonarIcon-h-dots {
  color: var(--primary);
}
