@import '../../scss/variables.scss';

.recently-viewed-edit-header {
  color: var(--nav-text);

  & .pointer-none {
    pointer-events: none;
  }

  & .pointer-auto {
    pointer-events: auto;
  }

  & .icon-wrap {
    background-color: var(--nav-text);
    border-radius: 60px;

    & i {
      color: var(--white);
      font-weight: normal;
    }
  }

  & .delete-icon {
    & i {
      color: var(--danger);
    }
  }
}

.recently-viewed-edit-body {
  & div {
    height: 50px;

    & button {
      color: var(--dark);

      &.pointer-none {
        pointer-events: none;
      }

      &.pointer-auto {
        pointer-events: auto;
      }

      & i.sonarIcon-asset {
        color: var(--primary);
      }

      & i.sonarIcon-map {
        color: var(--alt1);
      }

      & i.sonarIcon-place {
        color: var(--tertiary);
      }
    }
  }
}
