@import '../../../scss/variables.scss';

// Unit Section of triggers

.trigger-unit-form {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 25%;
  min-width: 45px;
}

// Contacts section of triggers

.contacts-remove {
  color: var(--danger);
}

//Bounds
.falls-below-input,
.rises-above-input {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

//proximity_location & proximity_gateway
.closer-than-form,
.farther-than-form,
.from-coords {
  width: 88px;
}

.map-form,
.gateway-units {
  width: 200px;
}

// PlaceForm

.place-form-trigger {
  & small {
    color: rgba(0, 0, 0, 0.54);
  }
}

// CreateTriggerWorkFlow

button.submit-trigger-workflow,
button.delete-trigger-workflow {
  width: 200px;
}

//movement

.form-movement-distance {
  width: 88px;
}

.form-movement-units {
  width: 120px;
}

//ability form

#falls-below {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  & form {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

#rises-above {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;

  & form {
    padding: 0;
    border-radius: 0;
  }
}
