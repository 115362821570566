@import '../../../scss//variables.scss';

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(1000px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu-bar-tour-map {
  margin: 17px;

  & .tour-start-button {
    color: var(--white);
  }
}

.collapse-button-hide-tour-map {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  position: absolute;
  bottom: 90px;
  right: -10px;
  z-index: 1000;

  & .card {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    background-color: var(--primary);
  }
}

.collapse-button-tour-map {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 60px;
  position: absolute;
  bottom: 90px;
  right: -10px;
  z-index: 999;
  animation: slide-in 0.65s ease-in-out;
  animation-fill-mode: forwards;

  & .card {
    border-top-left-radius: 12px !important;
    border-bottom-left-radius: 12px !important;
    background-color: var(--primary);
  }
}

.collapse-container-tour-map {
  display: flex;
  align-items: stretch;
}

.info-container-tour-map {
  position: absolute;
  bottom: 90px;
  right: 0;
  z-index: 1000;
  min-width: 250px;
  animation: slide-in 0.65s ease-in-out;
  animation-fill-mode: forwards;
  border-radius: 12px;

  & .card {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--primary);
  }
}

.info-container-hide-tour-map {
  position: absolute;
  bottom: 90px;
  right: 0;
  z-index: 1000;
  min-width: 150px;
  animation: slide-out 0.65s ease-in-out;
  animation-fill-mode: forwards;
  background-color: var(--primary);
  border-radius: 12px;

  & .card {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    background-color: var(--primary);
  }
}

.collapse-card-tour-map {
  margin: 2px 3px;
  padding: 10px;
  height: 100%;
  cursor: pointer;
  z-index: 1000;

  & i {
    transition: transform 0.5s ease-in-out;
    color: var(--white);
  }
}
