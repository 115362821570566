@import '../../scss/variables.scss';

.dropdown-menu.show {
  padding: 0;
}

.upload-btn {
  display: block;
  width: 100%;
  padding: 0.85rem 3rem !important;
  clear: both;
  font-weight: 400;
  text-align: inherit;
  white-space: nowrap;
  background-color: var(--white);
  border: 0;
}
