.thumbnail-container {
  width: 80px;
  display: block;
  position: relative;

  & :hover {
    cursor: pointer !important;
  }

  & div {
    display: flex;
    justify-content: center;
  }
}

.map-img-popover {
  width: 300px;
  height: 200px;
  padding: 10px;
  pointer-events: none;

  & img {
    object-fit: contain;
    width: 100%;
    height: 175px;
  }
}

.map-thumbnail-card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
