@import '../../../scss/variables.scss';

.map-list-card {
  overflow: hidden;

  & .map-list-empty {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  & .sonarIcon-map {
    color: var(--primary);
  }

  & button.create-map {
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      color: var(--primary) !important;
      background-color: var(--white) !important;
    }

    & i {
      cursor: pointer;
      padding-left: 8px;
    }
  }

  & .back-btn {
    color: var(--primary);
    border-color: var(--primary);
    padding: 6px 46px;

    &:hover {
      color: var(--white);
      background-color: var(--primary) !important;
    }
  }
}

@media (max-width: 1065px) {
  .ag-header-cell-sorted-none {
    & .maps-header {
      display: none;
    }
  }
}
