button.beacons {
  font-size: 14px;
  &.coords-set {
    text-decoration: none;
  }
}

button.confirm {
  &.enabled-btn {
    cursor: pointer;
  }
  &.disabled-btn {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
