.layout-progressbar {
  &.in-progress {
    height: 4px;
  }
  &.no-progress {
    height: 0px;
  }
  &.opened {
    margin-left: 275px;
  }
  &.closed {
    margin-left: 75px;
  }
}

.layout-main {
  display: flex;
  flex-direction: column;
  width: 100%;
  transition: margin-left 0.5s;
  padding: 20px;
  overflow: auto;
  padding-top: 75px;
  padding-bottom: 20px;
  height: 100vh;
  &.opened {
    margin-left: 275px;
  }
  &.closed {
    margin-left: 75px;
  }
}

.layout-main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.layout-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-bottom: 1.5em;
  height: 100%; /* Leave room for footer */
}

.layout-progressbar {
  transition: height 1s;
  transition-delay: 0.1s;
}
