@import '../../../../scss/variables.scss';

.beacon-reading-cell-abilities {
  & .sonarIcon {
    color: var(--primary);
  }
}

.multiple-readings-popup {
  border-radius: 16px;
  width: 600px;

  & button.view-asset {
    padding: 6px 20px;
  }
}
