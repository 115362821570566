@import '../../scss/variables.scss';

button {
  &.cursor-default {
    cursor: default !important;
  }

  &.primary-background {
    background-color: var(--primary);
  }

  &.secondary-background {
    background-color: var(--secondary);
  }

  & i.sonarIcon-add.sonarIcon-rotate-45 {
    color: var(--danger);

    &.keywords-delete-icon {
      color: var(--white);
    }

    &.exit-icon {
      color: var(--dark);
    }
  }
}
