@import '../../../../scss/variables.scss';

.associated-beacons-name-column {
  & .fs-lg {
    height: 30px;
  }

  & .fs-sm {
    height: 20px;
  }

  & .primarytag {
    font-weight: 400;
    color: var(--primary);
  }
}
