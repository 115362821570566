//Associated Beacons on asset detail page
.copy-icon {
  display: none;

  &:hover {
    background-color: transparent !important;
  }
}

.ag-row-hover .copy-icon {
  display: inline;
  position: absolute;
  bottom: -5px;
}

//devicemgmt gateway & beacons

.device-mgmt {
  .ag-row-hover .copy-icon {
    display: inline;
    position: absolute;
    right: -5px;
    top: -5px;
  }
}
