.pager {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  & .form-control {
    width: 60px;
  }

  & .sonarIcon-left-arrow,
  .sonarIcon-right-arrow {
    font-weight: normal;
    &.disable-next {
      cursor: auto;
      color: var(--nav-text);
    }
    &.enable-next {
      cursor: pointer;
      color: var(--primary);
    }
  }
  & span {
    white-space: nowrap;
  }
}
