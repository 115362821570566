// Small #F8BB86 ! Warning Icon
.sa {
  &-warning {
    border-radius: 50%;
    border: 2px solid #f8bb86;
    box-sizing: content-box;
    height: 30px;
    padding: 0;
    position: relative;
    background-color: #fff;
    width: 30px;

    &::after,
    &::before {
      background: var(--white);
      content: '';
      border-radius: 50%;
      height: 100%;
      position: absolute;
      width: 100%;
    }

    &::before {
      display: inline-block;
      opacity: 0;
    }

    &::after {
      display: block;
      z-index: 1;
    }

    &-body {
      background-color: #f8bb86;
      border-radius: 2px;
      height: 16px;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      bottom: 10px;
      width: 3px;
      z-index: 2;
    }

    &-dot {
      background-color: #f8bb86;
      border-radius: 50%;
      top: 22px;
      height: 3px;
      left: 50%;
      margin-left: -1px;
      position: absolute;
      width: 3px;
      z-index: 2;
    }
  }
}
