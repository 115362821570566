@import '../../scss/variables.scss';

.trigger-res-modal {
  width: 700px;
  margin: 60px auto auto;
  border-radius: 20px;

  & .loading {
    padding-bottom: 35px;
    margin: 255px 0;
  }

  & .exit-trigger {
    top: 10px;
    right: 20px;

    & button i {
      font-size: 24px;
      font-weight: 300;
    }
  }

  & .logo {
    height: 45px;
  }

  & .data-table {
    color: var(--dark);

    & .current-value {
      border-radius: 15px;
    }
  }

  & .alert {
    background-color: var(--warning) !important;
    color: var(--dark) !important;
  }
}

@media (max-width: 800px) {
  .trigger-res-modal {
    width: 600px;
  }
}
