//Light Mode
//Colors:
:root {
  --white: #fff;
  --primary: #0048ff;
  --primary-gray: #5988ff;
  --secondary: #a6a6a6;
  --nav-text: #808080;
  --danger: #f65449;
  --warning: #f8c74c;
  --pink: #e83e8c;
  --c-primary: #0048ff;
  --blue-back-highlight: #f4f7fe;
  --dark: #000;
  --light: #fff;
  --body: #f4f7f9;
  --tertiary: #d57438;
  --alt1: #e7bd54;
  --alt2: #61a4ac;
  --alt3: #8b5e95;
  --dropdown: #fff;
  --dropdown-hover: #f8f9fa;
  --cardBorderBottom: rgba(0, 0, 0, 0.125);
  --c-white: #fff;

  //Shadows:
  --box-shadow-right: 5px 5px 5px 20px rgba(0, 0, 0, 0.35);
  --box-shadow-left: -5px 5px 5px 0 rgba(0, 0, 0, 0.35);

  //Filter Invert
  --invert: invert(100%);
}

[data-theme='light'] {
  --white: #fff;
  --primary: #0048ff;
  --primary-gray: #5988ff;
  --primary-to-black: #0048ff;
  --secondary: #a6a6a6;
  --nav-text: #808080;
  --danger: #f65449;
  --c-primary: #0048ff;
  --back-highlight: #f4f7fe;
  --dark: #000;
  --light: #fff;
  --body: #f4f7f9;
  --tertiary: #d57438;
  --alt1: #e7bd54;
  --alt2: #61a4ac;
  --alt3: #8b5e95;
  --alt4: #d5d6d6;
  --dropdown: #fff;
  --dropdown-hover: #f8f9fa;
  --cardBorderBottom: rgba(0, 0, 0, 0.125);
  --coverage-grey: #000;
  --c-coverage-grey: #d7d7d7;
  --c-white: #fff;

  //Shadows:
  --box-shadow-right: 5px 5px 5px 0 rgba(0, 0, 0, 0.35);
  --box-shadow-left: -5px 5px 5px 0 rgba(0, 0, 0, 0.35);

  //Filter Invert
  --invert: invert(100%);
}

[data-theme='dark'] {
  --white: #202124;
  --primary: #fff;
  --primary-gray: #a6a6a6;
  --primary-to-black: #303134;
  --secondary: #a6a6a6;
  --nav-text: #808080;
  --danger: #f65449;
  --c-primary: #0048ff;
  --back-highlight: #000;
  --dark: #c2c2c2;
  --light: #202124;
  --body: #303134;
  --tertiary: #d57438;
  --alt1: #e7bd54;
  --alt2: #61a4ac;
  --alt3: #8b5e95;
  --alt4: #f4f7f9;
  --dropdown: #505050;
  --dropdown-hover: #c0c1c1;
  --cardBorderBottom: rgba(255, 255, 255, 0.125);
  --coverage-grey: #d7d7d7;
  --c-coverage-grey: #d7d7d7;
  --c-white: #fff;

  //Shadows:
  --box-shadow-right: 5px 5px 5px 0 rgba(0, 0, 0, 0.75);
  --box-shadow-left: -5px 5px 5px 0 rgba(0, 0, 0, 0.75);

  //Filter Invert
  --invert: invert(0%);
}

//Radius:
$card-radius: 1em;
$card-header-radius: 1em, 1em, 0, 0;
