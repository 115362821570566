@import '../../scss/variables.scss';

.dark-mode-toggle {
  background-color: var(--primary);
  width: 70px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 30px;
  position: relative;

  .inner-switch {
    background-color: var(--white);
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    transition: transform 0.3s ease;

    &.on {
      transform: translateX(40px);
    }
  }
}
