@import '../../scss/variables.scss';

.dashboard-device-status-header,
.dashboard-device-status-header-edit,
.dashboard-asset-chart-header-edit,
.dashboard-counter-header-edit {
  .sonarIcon-warning {
    color: var(--warning);
  }

  .sonarIcon-add {
    color: var(--danger);
  }
}

.dashboard-device-status-header .icon-wrap,
.dashboard-device-status-header-edit .icon-wrap,
.dashboard-asset-chart-header-edit .icon-wrap,
.dashboard-counter-header .icon-wrap,
.dashboard-counter-header-edit .icon-wrap {
  border-radius: 60px;

  &.beacon {
    background-color: var(--alt2);
  }

  &.gateway {
    background-color: var(--alt3);
  }

  &.asset {
    background-color: var(--primary);
  }

  &.map {
    background-color: var(--alt1);
  }

  &.place {
    background-color: var(--tertiary);
  }

  & i {
    color: var(--white);
    font-weight: normal;
  }
}

.dashboard-device-status-body-loading,
.dashboard-counter-body-loading {
  & .loading-txt {
    color: var(--dark);
    height: 400px;
  }
}

.dashboard-device-status-body {
  & .device-chart-div {
    border-radius: 1em;
    height: 400px !important;
  }
}

.dashboard-counter-body {
  & div {
    height: 50px;

    & i {
      font-weight: normal;

      &.asset {
        color: var(--primary);
      }

      &.map {
        color: var(--alt1);
      }

      &.place {
        color: var(--tertiary);
      }
    }
  }

  & span {
    color: var(--dark);
  }
}

.dashboard-device-status-body-no-data {
  height: 400px;
}
