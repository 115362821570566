@import '../../../scss/variables.scss';

.admin-portal-user-card {
  height: 90%;
  overflow: hidden;

  & .ag-theme-material.analytic-report {
    height: 620px !important;
  }
}

.user-select-msg {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 180px;

  & i {
    color: var(--primary);
  }
}

.admin-users-main-card {
  height: 90%;

  & .data-wrap {
    height: 690px;
  }
}

.invite-users-slide-out {
  & .exit i {
    color: var(--dark);
  }
}
