@import '../../scss/variables.scss';

form.scan-in {
  min-height: 200px;
}

.scaninTitle {
  display: flex;
  justify-content: left;
}

.next-btn:disabled,
.next-btn[disabled] {
  color: var(--white);
  background-color: var(--c-primary);
  border: 1px solid var(--c-primary);
}

.next-btn:disabled:hover,
.next-btn[disabled]:hover {
  color: var(--c-primary);
  background-color: var(--white);
  border: 1px solid var(--c-primary);
}

.next-btn {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  margin-top: 50px;
  width: 200px;
}

.next-btn:hover {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
