@import '../../../../scss/variables.scss';

.back-btn {
  color: var(--primary);
  border-color: var(--primary);
  width: 200px;
}

.back-btn:hover {
  color: var(--white);
  background-color: var(--primary);
}

.next-btn:disabled,
.next-btn[disabled] {
  color: var(--white);
  background-color: var(--c-primary);
  border: 1px solid var(--c-primary);
}

.next-btn:disabled:hover,
.next-btn[disabled]:hover {
  color: var(--c-primary);
  background-color: var(--white);
  border: 1px solid var(--c-primary);
}

.next-btn {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
  width: 200px;
}

.next-btn:hover {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}

#button-tooltip-create-asset > .tooltip-inner {
  width: 350px !important;
  max-width: none !important;
}

span.add-keyword,
span.key-value-pairs {
  & i.sonarIcon-add {
    color: var(--c-primary);
  }
}
