@import '../../scss/variables.scss';

.info-icon {
  color: var(--dark);
}

span.badge.badge-pill {
  margin: 0.7em;
  padding: 0.9em;
}

.id-key {
  font-size: 1.5em;
  margin-left: 10px;
}

.id-value {
  font-size: 1.5em;
  margin-left: 10px;
}

.swal2HtmlContainer {
  margin: 0 1.6em 0.3em !important;
}

.swal2-submitData {
  display: block;
  position: relative;
  top: 0;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 27px;
  padding-bottom: 20px;
  padding-top: 0.2em;
}

.key-value-badges {
  margin: 8px;
  padding: 10px;

  &.text-header input {
    background: transparent;
    border: 1px solid transparent;
    color: var(--dark);
    border-radius: 2em;
    height: 25px;
    padding-left: 10px;
  }

  &.new-key-value input {
    border: 1px solid var(--secondary);
    border-radius: 2em;
    height: 25px;
    padding-left: 10px;
  }

  & input.exist-edit {
    border: 1px solid var(--secondary);
    border-radius: 2em;
    height: 25px;
    padding-left: 10px;
  }

  & input.exist-normal {
    background: transparent;
    border: 1px solid transparent;
    color: var(--light);
    border-radius: 2em;
    height: 25px;
    padding-left: 10px;
  }
}

.edit-key-value-icon {
  color: var(--primary);
}

.delete-key-value-icon {
  color: var(--danger);
}

.duplicate-key-value-error {
  color: var(--danger);
  margin: 8px;
}

.existing-key-values {
  & .delete-exist-show {
    color: var(--danger);
    visibility: visible;
  }

  & .delete-exist-hide {
    visibility: hidden;
  }
}
