@import '../../src/scss/variables.scss';

.asset-filter-container {
  position: relative;

  & .asset-filter-container-btn {
    border: 1px solid #ced4da;
    background-color: var(--body);
    border-radius: 0.2rem;
    display: inline-block;
    font-size: 0.875rem;
    height: 100%;
    line-height: 1.5;
    padding: 0.25rem 0.5rem;
    text-align: center;
    vertical-align: middle;
  }

  & .actions {
    margin: 0.4rem 0;
  }

  & .filter-panel-inner {
    display: flex;
    height: 100%;
    justify-content: space-between;
    vertical-align: middle;
    margin: 0.4rem 0 0.8rem 0;
  }

  & .andor-filter-input-group {
    gap: 3px;
    width: auto !important;

    & button:hover {
      box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) !important;
    }
  }

  & .filter-by {
    color: var(--dark);
    line-height: 2;
  }

  & .filter-badge.badge {
    background-color: var(--primary) !important;
    color: var(--light);
    margin: 0;
  }
}

// Analytic Report Filter Container Btn
.analytic-report-selector {
  & .asset-filter-container-btn {
    height: 38px;
    margin-left: 0 !important;
  }
}
