.nav-tabs {
  border-bottom: none;
}

.nav-item {
  border-color: none;
}

.nav-tabs .nav-link.active {
  color: var(--primary);
  border: none;
}

.nav-tabs .nav-link {
  font-size: 1.25em;
  padding-bottom: 0;
  color: var(--dark);
  border: none;
}

.nav-link.active {
  padding-bottom: 0;
  border-bottom: 2px solid var(--primary) !important;
}

.nav-tabs > a {
  font-size: 1.25em;
  outline: none;
}
