@import '../../scss/variables.scss';

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(1000px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(1000px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.leaflet-draw-tooltip {
  display: none;
}

.menu-bar {
  width: 100%;
}

.collapse-button-hide {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 103.2px;
  position: absolute;
  top: 95px;
  right: -10px;
  z-index: 1000;
}

.collapse-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 103.2px;
  position: absolute;
  top: 95px;
  right: -10px;
  z-index: 1005;
  animation: slide-in 0.75s ease-in-out;
  animation-fill-mode: forwards;
}

.collapse-container {
  display: flex;
  align-items: stretch;
}

.info-container {
  position: absolute;
  top: 95px;
  right: 0;
  z-index: 1000;
  min-width: 625px;
  animation: slide-in 0.75s ease-in-out;
  animation-fill-mode: forwards;
}

.info-container-hide {
  position: absolute;
  top: 95px;
  right: 0;
  z-index: 1000;
  min-width: 625px;
  animation: slide-out 0.75s ease-in-out;
  animation-fill-mode: forwards;
}

.collapse-card {
  padding: 8px;
  height: 100%;
  cursor: pointer;
  z-index: 1000;
}

.title-bar {
  width: 100%;
  white-space: nowrap;
  transition: max-width 0.5s ease-in-out;
  background-color: var(--light);
}

.objects-bar {
  width: 100%;
  background-color: var(--light);

  & button > i {
    margin-right: 8px;
  }
}

h4.text:hover {
  max-width: 100vw;
  transition: max-width 0.5s ease-in-out;
  transition-delay: 0.5s;
}

h4.text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15vw;
  transition: max-width 0.5s ease-in-out;
}

.filter-menu {
  position: fixed;
  left: 165px;
}

.stationary-filter-menu {
  position: fixed;
  left: 200px;
  z-index: 2;
}

.typeahead-menu {
  position: fixed;
  top: 170px;
  width: 503px;
  z-index: 1;
}

.input-placeholder {
  height: 38px;
}

.placeholder {
  background-color: transparent;
  width: 63px;
}

.floor-input {
  position: fixed;
  top: 270px;
  width: 503px;
  z-index: 0;
}

.dropdown-menu.show {
  z-index: 1000 !important;
}

.search-bar {
  margin-left: 8px;
  max-width: 0%;
  transition: max-width 0.5s ease-in-out;
}

.search-bar-expand {
  margin-left: 8px;
  max-width: 100%;
  transition: max-width 0.5s ease-in-out;
}

.stat-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  border: none !important;
  padding: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.object-list {
  background-color: var(--light);
  max-height: 0;
  margin-left: 50px;
  margin-right: 0;
}

.object-list-expand {
  background-color: var(--light);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  position: absolute;
  left: 50px;
  width: -webkit-fill-available;
}

.list-container {
  margin-top: 50px;
}

.list-container-edit {
  margin-top: 115px;
}

.object-edit {
  width: 100%;
  padding: 20px;
}

input#mapName {
  border: none;
  border-bottom: solid 1px;
  background: none;
  color: var(--dark) !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15vw;

  &:focus-visible {
    outline: none;
    background: none;
  }
}

.list-item {
  padding: 0.1rem 0.8rem;
  margin: 0.25rem auto;
  width: 100%;
  border: 1px solid var(--secondary);
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    visibility: hidden;
  }

  &:hover {
    border: 1px solid var(--dark);
    cursor: pointer;

    i {
      visibility: visible;
    }
  }
}

.new-item {
  padding: 0.1rem 0.8rem;
  margin: 0.25rem auto;
  width: 100%;
  border: 2px solid var(--c-primary);
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    visibility: hidden;
  }

  &:hover {
    border: 2px solid var(--dark);
    cursor: pointer;

    i {
      visibility: visible;
    }
  }
}

.deleted-item {
  padding: 0.1rem 0.8rem;
  margin: 0.25rem auto;
  width: 100%;
  border: 2px solid var(--danger);
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    visibility: hidden;
  }

  &:hover {
    border: 2px solid var(--dark);
    cursor: pointer;

    i {
      visibility: visible;
    }
  }
}

.list-info {
  display: flex;
  flex-direction: row;
  width: 85%;
}

.header-btns {
  padding: 0.1rem 0.5rem;
  width: 100%;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  justify-content: left;
  align-items: center;
}

.title-text {
  padding: 6px;
}

.object-wrapper {
  background-color: var(--light);
  margin: 10px;
  padding: 10px;
  scrollbar-gutter: stable;
  overflow-y: auto;
  resize: vertical;
  min-height: 60px;
  max-height: calc(100vh - 300px);
}

.object-wrapper-edit {
  background-color: var(--light);
  margin: 10px;
  padding: 10px;
  scrollbar-gutter: stable;
  overflow-y: auto;
  resize: vertical;
  min-height: 115px;
  max-height: calc(100vh - 300px);
}

.object-wrapper-none {
  display: none;
}

.pinned-controls {
  background-color: var(--white);
  position: fixed;
  padding: 20px;
  width: 545px;
  top: 103.2px;
  left: 50px;
  z-index: 999;
  border-bottom-left-radius: 1em;
}

.pinned-controls-edit {
  background-color: var(--white);
  position: fixed;
  padding: 20px;
  width: 545px;
  top: 103.2px;
  left: 50px;
  z-index: 999;
  border-bottom-left-radius: 1em;
}

.pinned-controls-none {
  display: none;
}

.keywords-wrap {
  max-width: 391px;
  display: flex;
  flex-wrap: wrap;
}

.name-col {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: small;
  width: 220px;
}

.name-col:hover {
  overflow: visible;
}

.checkin-col {
  overflow: hidden;
  justify-content: end;
  align-items: end;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 35%;
  font-size: small;
  font-weight: lighter;
}

.checkin-col:hover {
  overflow: visible;
}

.btns-col {
  display: flex;
  flex-direction: row;
  justify-content: end;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: auto;
}

#loading {
  animation: loading 3s linear infinite;
}

.rbt {
  width: 100%;
}

.status-row {
  height: 40px;
}

.card-img-map {
  height: 24rem;
}

.last-checkin {
  padding-left: 1rem;
  padding-top: 1rem;
}

.danger-icon {
  color: var(--danger);
}

.primary-icon {
  color: var(--primary);
}

.nav-text-icon {
  color: var(--nav-text) !important;
}

.sort-icon {
  writing-mode: vertical-rl;
  text-orientation: upright;
  letter-spacing: -10px;
}

.back-button {
  position: absolute;
  top: 10px;
  right: 20px;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  display: none !important;
}

.edit-feature {
  padding: 12px;
}

.edit-field {
  width: 75%;
}
