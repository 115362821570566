@import './../../scss/variables.scss';

.map-detail-card-wrap {
  & .sonarIcon-star-empty,
  .sonarIcon-star-fill {
    color: var(--primary);
  }
}

.map-input {
  color: var(--dark) !important;
}

.map-detail-container {
  overflow: hidden;

  & .row {
    padding: 5px 0;
  }

  & .btn-group {
    gap: 4px;
  }
}

.location-map-container {
  height: 90%;
}

.map-name-primary {
  color: var(--primary);
}
