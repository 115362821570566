@import './../../scss/variables.scss';

.trend-filter-container {
  & .col-6 {
    padding-right: 10px;
  }

  & .col-6 .form-control {
    padding-left: 5px;
  }

  & .form-control {
    padding-left: 5px;
    padding-right: 2px;
  }

  & .input-col {
    padding-left: 10px;
    padding-right: 0;

    & .form-control {
      padding: 5px;
      text-align: center;

      &::placeholder {
        color: lightgray;
      }
    }
  }

  & #trend-filter-error {
    color: var(--danger);
    font-weight: 400;
  }

  // Remove up and down arrows for inputs
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
}
