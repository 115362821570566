@import '../../scss/variables.scss';

.analytic-report-selector {
  & .header-input {
    width: 420px;
  }
}

.fa-bell {
  color: var(--primary);
}

.submit-btn {
  margin-left: auto !important;
  text-align: right;
}

@media (max-width: 1550px) {
  .analytic-report-selector {
    & .header-input {
      width: 25%;
    }
  }
}

@media (max-width: 1400px) {
  .analytic-report-selector {
    & .date-filter {
      width: 40%;
    }
    & .header-input {
      width: 25%;
    }
  }
}

@media (max-width: 1260px) {
  .analytic-report-selector-wrapper {
    flex-wrap: wrap;
  }
  .analytic-report-selector {
    & .date-filter {
      width: 50%;
    }
    & .header-input {
      width: 100%;
    }
  }
}

@media (max-width: 1095px) {
  .submit-btn {
    margin-left: auto !important;
  }
}

@media (max-width: 950px) {
  .analytic-report-selector {
    & .date-filter {
      width: 67%;
    }
    & .header-input {
      width: 30%;
    }
  }
  .submit-btn {
    margin-left: auto !important;
  }
}

@media (max-width: 880px) {
  .analytic-report-selector {
    & .header-input {
      width: 100%;
    }
    & .date-filter {
      width: 100%;
    }
  }
  .submit-btn {
    margin-left: unset !important;
  }
}

.analytic-report-selector {
  & .MuiFormHelperText-root {
    margin-top: 1px !important;
    margin-left: 0px !important;
    position: absolute;
    top: 34px;
    width: 225px;
  }

  & .map-select {
    width: 250px;
  }
}

@media (max-width: 1213px) {
  .analytic-report-selector {
    & .map-select {
      flex: 1;
    }
  }
}
