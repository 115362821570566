@import '../../../scss/variables.scss';

// Header CSS

:root {
  --nav-height: 50px;
}

// Left Thinaer Header Logo

header.header {
  position: fixed;
  background-color: transparent;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.header-left {
  transition: opacity 0.3s ease;
  padding-left: 20px;
  z-index: 999;
  pointer-events: auto;
  width: 275px;
  height: 55px;

  & svg {
    fill: var(--primary);
    height: 45px;
  }

  & button {
    padding: 3px;
  }

  & img {
    padding-left: 10px;
  }

  & .logo.show {
    opacity: 1;
  }

  & .logo.hide {
    opacity: 0;
  }

  & .logo.no-marginl {
    margin-left: 0;
  }

  & .logo.reg-margin {
    margin-left: 55px;
  }
}

// Right header icons

.header-icon {
  font-size: 1.6em;
}

.logo {
  transition: 0.5s;
}

.header-list {
  transition: opacity 0.3s ease;
  list-style: none;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: auto;

  &.visible {
    opacity: 1;
  }

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  & .text {
    color: var(--dark);
  }

  & li {
    display: inline-block;

    & button {
      color: var(--nav-color);
    }
  }

  #application-switcher-btn,
  #actions-btn,
  #favorites-btn,
  #settings-btn,
  #edit-dash-btn {
    & i.sonarIcon {
      &.primary-color {
        color: var(--primary);
      }

      &.dark-color {
        color: var(--dark);
      }
    }
  }
}

.popover {
  min-width: 200px;
}

.application-popover {
  & .popover-body {
    overflow: hidden;
    max-height: 450px;
  }
}

// NavToggle

.nav-hamburger {
  color: var(--primary);
}

// Create New Dropdown

.create-new-dropdown {
  & .list-group-item-action:hover {
    background-color: var(--dropdown-hover);
  }

  & i {
    color: var(--primary);
  }
}

// Application switcher

.application-switcher-inner-list {
  max-height: 400px;
  overflow-y: scroll;

  & .list-group-item {
    color: var(--primary);
    background-color: var(--dropdown);

    & a {
      color: var(--primary);
    }

    & span {
      font-weight: normal;

      &.app-text {
        color: var(--primary);
      }

      &.selected {
        font-weight: 800;
        color: var(--primary);
      }
    }
  }
}

// Edit Dashboard

#edit-dash-btn {
  & i.header-icon {
    &.editing {
      color: var(--primary);
    }

    &.not-editing {
      color: var(--dark);
    }
  }
}
