.marker-pin {
  width: 35px;
  height: 35px;
  border-radius: 50% 50% 50% 0;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.marker-pin::after {
  content: '';
  width: 29px;
  height: 29px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.custom-div-icon i {
  position: absolute;
  width: 16px;
  font-size: 16px;
  left: 0;
  right: 0;
  margin: 12px auto;
  text-align: center;
}

.custom-div-icon b {
  position: absolute;
  width: 30px;
  font-size: 11px;
  left: 0;
  right: 0;
  margin: 15px auto;
  margin-left: 2px;
  text-align: center;
}

.custom-div-icon i.awesome {
  margin: 12px auto;
  font-size: 17px;
}
