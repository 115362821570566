@import '../../scss/variables.scss';

.no-reading-container {
  & .text {
    display: flex;
    color: var(--dark);
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.reading-container-header {
  & .delete-icon {
    & i {
      color: var(--danger);
    }
  }

  & .sonarIcon-calendar {
    color: var(--primary);
  }
}

.avail-reading-container {
  overflow: hidden;

  & .text:nth-child(n + 2):nth-child(-n + 3) {
    display: flex;
    color: var(--dark);
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

.reading-container-header {
  & .fa-chevron-left,
  .fa-chevron-right {
    color: var(--primary);
  }
}

.sonarIcon-analytics {
  color: var(--primary);
}

.info-icon-span {
  & .info-icon-div {
    pointer-events: 'none';
    background-color: transparent;
  }

  & .sonarIcon-info {
    color: var(--dark);
  }
}

.asset-reading-calendar-row {
  & .sonarIcon-calendar {
    color: var(--primary);
  }
}

.sonarIcon-info {
  color: var(--dark);
}
