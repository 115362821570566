@import '../variables.scss';

.sidenav {
  &.opened {
    width: 275px;
  }
  &.closed {
    width: 75px;
  }
}
nav.sidenav {
  height: 100%;
  position: fixed;
  z-index: 1;
  bottom: -75;
  top: 0;
  left: 0;
  background-color: var(--white);
  overflow-x: hidden;
  transition: 0.5s;
  &.expanded {
    width: 275px;
  }
  &.minimize {
    width: 75px;
  }
  & .icon-wrap {
    border-radius: 60px;
  }
  & .icon-wrap.active {
    background-color: var(--primary);
  }
  & .icon-wrap.not-active {
    background-color: transparent;
  }
  & .icon-wrap {
    & .active-sys-admin {
      color: var(--primary) !important;
      transform: rotate(180deg) !important;
    }
    & .active-sys-admin-hovered {
      color: var(--nav-text) !important;
    }
    & .sonarIcon-down-arrow {
      font-weight: normal;
      color: transparent;
      transition: 0.2s;
      transform: rotate(0deg);
    }
    & i.active {
      color: var(--white);
      font-weight: normal;
    }
    & i.not-active {
      color: var(--nav-text);
      font-weight: normal;
    }
  }
  button span.text.active {
    color: var(--primary);
  }
  button span.text.active {
    color: var(--dark);
  }
}

.nav-links {
  margin-top: 100px;
}

.nav-margin-top {
  margin-top: 1rem;
}

@media screen and (max-height: 700px) {
  .nav-margin-top {
    margin-top: 0.5rem;
  }
}
