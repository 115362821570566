@import '../../scss/variables.scss';

.device-mgmt-loading {
  color: var(--dark);
}

.collapse-wrap-btn.position-absolute {
  right: 0;
  bottom: 1px;
}

.device-stats-collapse-btn {
  background-color: var(--white) !important;
  border: none !important;

  &:hover,
  &:focus,
  &:active {
    background-color: var(--white) !important;
    border: none !important;
  }

  & i.sonarIcon-right-arrow {
    font-weight: 800;
    transform: rotate(270deg);
    transition: transform 0.2s ease-in-out;
    color: var(--alt4);

    &.open {
      transform: rotate(270deg);
    }

    &.close {
      transform: rotate(90deg);
    }
  }
}

.device-mgmt-stats-body {
  display: flex;

  & .status-counts {
    border-right: solid 1px var(--bs-card-border-color);

    & .wrap-data-count {
      justify-content: space-around;
    }
  }

  & .status-text {
    width: 150px;
  }

  & .deployment-text {
    width: 200px;
  }

  .online {
    color: var(--c-primary);
  }

  .offline {
    color: var(--danger);
  }

  .inventory {
    color: var(--nav-text);
  }

  .deployed {
    color: var(--primary-gray);
  }
}
